<template>
  <div>
    <h6 class="font-weight-bolder">Families</h6>
    <div class="row px-2 mb-4">
      <table class="table table-sm table-bordered small">
        <thead>
        <tr>
          <th>#</th>
          <th>Full Name</th>
          <th>Relationship</th>
          <th>Birthday</th>
          <th>Address</th>
          <th>Contact</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="crewInfo.crewFamily">
          <tr v-for="(family,index) in crewInfo.crewFamily">
            <td>{{index + 1}}</td>
            <td>{{family.fullName ? family.fullName.toUpperCase() : ''}}</td>
            <td>{{family.relationship ? family.relationship.toUpperCase() : ''}}</td>
            <td>{{family.birthdate ? family.birthdate : ''}}</td>
            <td>{{family.address ? family.address.toUpperCase() : ''}}</td>
            <td>{{family.contact ? family.contact : ''}}</td>
          </tr>
        </template>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CrewFamily",
  computed:{
    ...mapGetters(['crewInfo'])
  }
}
</script>

<style scoped>

</style>
